import { ILocation, IPublicModel } from "models/Model";
import Axios from "axios";
import Papa from 'papaparse';

export class ModelService {
  static getModelGpsPath(model: IPublicModel): Promise<ILocation[]> {
    return new Promise((res, rej) => {
      Axios.get(model.assets.gpsUrl)
        .then((response) => {
          const locations: ILocation[] = [];
          const pathsData = response.data;
          const parseResult = Papa.parse(pathsData);
          const paths = parseResult.data;
          paths
            .filter((flightData, index) => index % 5 === 0)
            .map((flightData) => {
              const latitude = Number(flightData[1]);
              const longitude = Number(flightData[2]);
              if (latitude && longitude) {
                locations.push({ latitude, longitude });
              }
            });
          res(locations);
        })
        .catch(() => rej([]));
    });
  }
}
