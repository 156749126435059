import React from "react";
import { ModelType } from "../../../../views/interfaces";
import Button from "../../buttons/Button";
import UploadIcon from "../../Icons/UploadIcon";
import ModelIcon from "../../Icons/ModelIcon";
import ArrowDownIcon from "../../Icons/ArrowDownIcon";
import TutorialIcon from "../../Icons/TutorialIcon";
import {
  INFORMATION_ROUTES,
  INTERIOR_AI_ROUTES,
  SESSION_ROUTES,
} from "../../../../views/routes";
import SkyeDropdown, { DropdownWrapper } from "../Dropdown/Dropdown";
import DropdownItem from "../Dropdown/DropdownItem";
import { getModelListPageUrl } from "../../../../utils/globalUtils";
import TacbrowseIcon from "../../Icons/TacbrowseIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { IOpenUserRole, UserHelpers } from "../../../../models/User";
import { SessionAccessPolicy } from "../../../session/SessionAccessPolicy";
import { SubscriptionType } from "../../../payments/payments.model";
import InteriorAIIcon from "../../Icons/InteriorAIIcon";

interface IAuthorizedHeaderPropTypes extends RouteComponentProps {
  GlobalStore?: IGlobalStore;
}

/**
 * Header displayed for authorized users on web view.
 * Completely not visible for mobile view
 */
const AuthorizedDesktopNavbarContent = ({
  history,
  GlobalStore,
}: IAuthorizedHeaderPropTypes) => {
  const { t } = useTranslation();
  const showTacbrowse = SessionAccessPolicy.hasSessionAccess(GlobalStore.user);
  const showOrganization =
    UserHelpers.hasRole(
      GlobalStore.user,
      IOpenUserRole.OrganizationMaintainer
    ) || GlobalStore.isOwner();

  const redirectToModelsPage = (modelType: ModelType) => () => {
    history.replace(getModelListPageUrl(modelType));
  };

  const redirectToSessionsPage = () => () => {
    history.replace(SESSION_ROUTES.home);
  };

  return (
    <>
      <Button
        color="accent"
        type="solid"
        startIcon={<UploadIcon />}
        className="mr-2"
        onClick={() => {
          GlobalStore.openUploadDialog();
        }}
      >
        {t("uploadVideo")}
      </Button>
      <SkyeDropdown
        onChange={() => {}}
        mouseEnterDelay={0.7}
        overlay={
          <DropdownWrapper>
            <DropdownItem onClick={redirectToModelsPage(ModelType.Owned)}>
              {t("owned")}
            </DropdownItem>
            <DropdownItem onClick={redirectToModelsPage(ModelType.Shared)}>
              {t("sharedToMe")}
            </DropdownItem>
            <DropdownItem
              onClick={redirectToModelsPage(ModelType.SharedToOthers)}
            >
              {t("sharedToOthers")}
            </DropdownItem>
            {showOrganization && (
              <DropdownItem
                onClick={redirectToModelsPage(ModelType.Organization)}
              >
                {t("organization")}
              </DropdownItem>
            )}
            <DropdownItem onClick={redirectToModelsPage(ModelType.Archived)}>
              {t("archived")}
            </DropdownItem>
          </DropdownWrapper>
        }
      >
        <Button
          type="transparent"
          color="dark"
          startIcon={<ModelIcon />}
          endIcon={<ArrowDownIcon />}
          onClick={redirectToModelsPage(ModelType.Owned)}
        >
          {t("models")}
        </Button>
      </SkyeDropdown>
      <Button
        className="video-tutorials-btn"
        type="transparent"
        color="dark"
        startIcon={<TutorialIcon />}
        onClick={() => {
          history.push(INFORMATION_ROUTES.home);
        }}
      >
        {t("videoTutorials")}
      </Button>
      <Button
        className="video-tutorials-btn"
        type="transparent"
        color="dark"
        startIcon={<InteriorAIIcon />}
        onClick={() => {
          history.push(INTERIOR_AI_ROUTES.home);
        }}
      >
        {t("navbar.interiorAi")}
      </Button>
      {showTacbrowse && (
        <Button
          type="transparent"
          color="dark"
          startIcon={<TacbrowseIcon />}
          onClick={redirectToSessionsPage()}
        >
          {t("sessions")}
        </Button>
      )}
    </>
  );
};

export default inject("GlobalStore")(
  observer(withRouter(AuthorizedDesktopNavbarContent))
);
