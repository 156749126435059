import React, { ReactNode } from "react";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import PublicNavbar from "../components/Navbar/Navbar";

interface IBasePublicPagePropTypes {
  children: ReactNode;
}

const StyledBasePublicPage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
`;

const Content = styled.div`
  display: flex;
  height: 100%;
`;

const PublicPageLayout = ({ children }: IBasePublicPagePropTypes) => {
  return (
    <StyledBasePublicPage>
      <PublicNavbar />
      <Content>{children}</Content>
    </StyledBasePublicPage>
  );
};

export default PublicPageLayout;
