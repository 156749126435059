import { IPaymentDialogPropTypes } from "./model";
import StripeCheckout from "../../stripe/Checkout/StripeCheckout.component";
import { useCurrentUser } from "../../../account/context/user.context";

const StripeDialogContent = ({
  stripeMetadata,
  returnUrl,
}: IPaymentDialogPropTypes) => {
  const { user } = useCurrentUser();

  return (
    <StripeCheckout
      amountInDolars={stripeMetadata.amount / 100}
      clientSecret={stripeMetadata.client_secret}
      returnUrl={returnUrl}
      userEmail={user?.email || ""}
      userFirstName={user?.firstName || ""}
      userLastName={user?.lastName || ""}
    />
  );
};

export default StripeDialogContent;
