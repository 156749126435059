import { useTranslation } from "react-i18next";
import SubscriptionInformationRow from "../../SubscriptionInformationRow/SubscriptionInformationRow";
import { PREMIUM_PRICING_QUOTE_SUVERY_URL } from "../../../../payments/components/PricingTable/PricingTable.const";

const redirectToPremiumQoteSurvey = () => {
  window.location.href = PREMIUM_PRICING_QUOTE_SUVERY_URL;
};

const LiteSubscriptionInfo = () => {
  const { t } = useTranslation();

  return (
    <div id="subscription-expiring-info" className="subscription-info">
      <SubscriptionInformationRow
        iconColor="blue"
        color="transparent"
        icon="diamond"
        primaryText={t(`subscription.lite.upgradeInfoTitle`)}
        secondaryText={t("subscription.lite.upgradeInfoDescription")}
      ></SubscriptionInformationRow>
    </div>
  );
};

export default LiteSubscriptionInfo;
