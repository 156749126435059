import { getEnvVariables } from "../../../../env";
import HttpService from "../../../../services/HttpService";
import { IModelIssueReq } from "../model/Issue";

const env = getEnvVariables();

export class IssueService {
  static async reportPublicModel(publicKey: string, data: IModelIssueReq) {
    const url = `${env.API_V3_URL}v3/public/models/${publicKey}/issues`;
    return HttpService.post(url, data);
  }

  static async reportModel(id: string, data: IModelIssueReq) {
    const url = `${env.API_V3_URL}v3/public/models/${id}/issues`;
    return HttpService.post(url, data);
  }
}
