import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Dialog from "../../../shared/components/Dialog/Dialog";
import Description from "../../../shared/typography/Description";
import GlobalStore from "../../../../stores/GlobalStore";
import { getResponseErrorTranslation } from "../../../../utils/globalUtils";
import ModelService from "../../services/ModelService";

interface DeleteModelDialogProps {
  open: boolean;
  onClose: () => void;
  onDeleted: () => void;
  model: {
    id: string;
    name: string;
  };
}

const DeleteModelDialog = ({
  open,
  onClose,
  onDeleted,
  model,
}: DeleteModelDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteModel = () => {
    setLoading(true);
    ModelService.deleteModel(model.id)
      .then(() => {
        setLoading(false);
        onDeleted();
      })
      .catch((error) => {
        setLoading(false);
        GlobalStore.addToast(getResponseErrorTranslation(error), "error");
      });
  };

  return (
    <Dialog
      title={t("deleteModel")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        children: t("deletePermanently"),
        loading,
        onClick: deleteModel,
      }}
    >
      <Description>
        <Trans values={{ modelName: model.name }}>deleteModelDescription</Trans>
      </Description>
    </Dialog>
  );
};

export default DeleteModelDialog;
