import { createContext, useContext, useMemo, useState } from "react";
import { IOpenUserRole, LoggedUser, UserHelpers } from "../../../models/User";

interface IUserContext {
  user: LoggedUser | null;
  setUser: (user: LoggedUser) => void;
}

export const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => {},
});

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // On this level we can ensure token exists - should be used after auth guard
  const [user, setUser] = useState<LoggedUser | null>(null);

  return (
    <UserContext.Provider value={{ user: user as LoggedUser, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  const ctx = useContext(UserContext);
  if (!ctx) {
    throw new Error("useCurrentUser must be used within a UserContextProvider");
  }

  const { user } = ctx;

  const setUser = (user: LoggedUser) => {
    ctx.setUser(user);
  };

  const logout = () => {
    ctx.setUser(null);
  };

  const hasRole = (role: IOpenUserRole) => {
    return UserHelpers.hasRole(user, role);
  };

  const isOwner = useMemo(() => {
    if (!user || !user.organization) return false;
    return user.id === user.organization.ownerId;
  }, [user]);

  return { user: ctx.user, isOwner, setUser, logout, hasRole };
};
