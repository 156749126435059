import { useTranslation } from "react-i18next";
import { useSubscriptionPaymentDialog } from "../../../../models/payments/useSubscriptionPaymentDialog";
import SubscriptionInformationRow from "../../SubscriptionInformationRow/SubscriptionInformationRow";
import Button from "../../../buttons/Button";

const ExpiredSubscriptionInfo = () => {
  const subscription = useSubscriptionPaymentDialog();
  const pricingDialog = subscription.content;
  const { t } = useTranslation();

  return (
    <div className="subscription-info">
      {pricingDialog}
      <SubscriptionInformationRow
        iconColor="primary"
        primaryText={t("subscription.upgradeInfoTitle")}
        secondaryText={t("subscription.upgradeInfoDescription")}
        button={
          <Button
            className="w-max"
            color="primary"
            size="small"
            onClick={subscription.openDialog}
          >
            {t("subscription.subscribeNowBtn")}
          </Button>
        }
      ></SubscriptionInformationRow>
    </div>
  );
};

export default ExpiredSubscriptionInfo;
