import { useState } from "react";
import { Model, ModelStatus } from "../../../models/Model";
import {
  AccessPeriod,
  Quote,
  QuoteService,
} from "../../payments/service/QuoteService";
import { useDialog } from "../../shared/components/Dialog/useDialog";
import { StripePaymentIntend } from "../../payments/stripe/Stripe.model";
import { useSubscriptionPayment } from "./useSubscriptionPayment";
import paymentUtils from "./payments.utils";
import { FacebookConversionApi } from "../../analytic/facebook/conversion.api";
import { useCurrentUser } from "../../account/context/user.context";

export const useModelPaymentWidget = () => {
  const { user } = useCurrentUser();
  const [returnUrl, setReturnUrl] = useState("");
  const dialog = useDialog();
  const { open } = dialog;
  const [quote, setQuote] = useState<Quote>(null);
  const [stripeMetadata, setStripeMetadata] =
    useState<StripePaymentIntend>(null);
  const [loading, setLoading] = useState(false);
  const subscription = useSubscriptionPayment();

  const getOptionsFromQuote = (quote: Quote) => {
    const { prices } = quote.data;

    return Object.keys(prices).map((key) => {
      return {
        id: key,
        name: key,
        price: prices[key],
      };
    });
  };

  const getQuote = async (modelId: string) => {
    try {
      setLoading(true);
      const res = await QuoteService.getModelQuote(modelId);
      setQuote(() => res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    dialog.closeDialog();
  };

  const openDialog = (modelId: string, modelStatus: string) => {
    // Reset do default state
    setReturnUrl(
      modelStatus === ModelStatus.Archived
        ? paymentUtils.getModelListPaymentRedirectUrl()
        : paymentUtils.getModelPaymentRedirectUrl(modelId)
    );
    dialog.openDialog();
    setStripeMetadata(null);

    getQuote(modelId);
  };

  const sendAnalyticEvent = async (amount: number) => {
    FacebookConversionApi.sendEvent(
      "AddToCart",
      {
        customerFirstName: user?.firstName || "",
        customerLastName: user?.lastName || "",
        customerEmail: user?.email || "",
      },
      {
        currency: "USD",
        value: amount,
      }
    );
  };

  const handleBuyAccess = async (accessPeriod: AccessPeriod) => {
    try {
      setLoading(true);
      const res = await QuoteService.fulfill(quote.id, accessPeriod);
      sendAnalyticEvent(res.data.amount);
      setStripeMetadata(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getContentType = () => {
    if (stripeMetadata) {
      return "stripe";
    }

    return "pickOption";
  };

  return {
    quote,
    stripeMetadata,
    contentType: getContentType(),
    open,
    loading,
    options: quote ? getOptionsFromQuote(quote) : [],
    returnUrl,
    subscriptionPeriod: subscription.subscriptionPeriod,
    setSubscriptionPeriod: subscription.setSubscriptionPeriod,
    openDialog,
    handleClose,
    handleBuyAccess,
    handleBuySubscription: subscription.redirect,
  };
};
