import { useContext } from "react";
import { ModelDetailsPageContext } from "./ModelDetailsPage.context";
import ModelService from "../../services/ModelService";
import { Model } from "../../../../models/Model";

export const useModelDetailsPage = () => {
  const ctx = useContext(ModelDetailsPageContext);
  if (!ctx) {
    throw new Error(
      "useModelDetailsPage must be used within ModelDetailsPageProvider"
    );
  }

  const loadModel = async (modelId: string) => {
    try {
      const response = await ModelService.getModelDetails(modelId);
      ctx.setModel(response?.data);
      return response?.data || null;
    } catch (error) {
      return null;
    }
  };

  const init = async (modelId: string): Promise<Model | null> => {
    return loadModel(modelId);
  };

  return {
    init,
    loadModel,
    ...ctx,
  };
};
