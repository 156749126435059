import React, { ReactNode } from "react";
import styled from "styled-components";
import Box, { BoxAlignedCenter } from "views/shared/components/Box";
import { IAppContext } from "views/interfaces";
import Heading from "views/shared/typography/Heading";
import Button from "views/shared/buttons/Button";
import EditIcon from "views/shared/Icons/EditIcon";
import ShareIcon from "views/shared/Icons/ShareIcon";
import { trimStringIfToLong } from "utils/stringUtils";
import CommentsCountButtonContainer from "../../../containers/CommentsCountButtonContainer";
import { useTranslation } from "react-i18next";
import { ModelPageViewTypes } from "const/global";
import { IPublicModel, ITypedModelType, ModelStatus } from "models/Model";
import useBreakpoint from "views/shared/hook/useBreakpointHook";
import breakpoint from "styled-components-breakpoint";
import withReportIssueModal from "../../../issue/hoc/withReportIssueModal";
import ReportIssueButton from "./ReportIssueButton";
import ModelViewTypeButton from "../../../components/ModelViewTypeButton";
import SkyeTooltip from "../../../../shared/components/SkyeTooltip";
import DownloadAssetsButton from "./DownloadAssetsButton";
import { IGlobalStore } from "../../../../../stores/GlobalStore";

export interface IModelDetailsRowPropTypes {
  GlobalStore?: IGlobalStore;
  isPublic?: boolean;
  model: IPublicModel;
  view?: ModelPageViewTypes;
  topContent?: ReactNode;
  showCommentsModule?: boolean;
  showEditNameButton?: boolean;
  showShareButton?: boolean;
  showDownloadButton?: boolean;
  onViewChange: (viewType: ModelPageViewTypes) => void;
  onEditName?: () => void;
  onDownload?: () => void;
  onShare?: () => void;
}

const DesktopModelDetailsRow = styled(Box)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.small};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  width: 100%;

  ${breakpoint(`md`)`
    flex-direction: row;
    height: 72px;
    padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.medium};
  `}
`;
const ModelDetailsLeftBox = styled(Box)`
  align-items: center;
  justify-content: flex-start;
`;
const ModelDetailsRightBox = styled(Box)`
  .btn {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;
const MobileHeadingRow = styled.div`
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium} 0;
`;
const MobileBtnRow = styled(BoxAlignedCenter)`
  width: 100%;
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};

  &,
  .view-btn {
    width: 100%;
  }

  .share-btn {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};

    .end-icon {
      margin-left: 0;
    }
  }
`;

const ModelDetailsRow = ({
  isPublic,
  showCommentsModule,
  showEditNameButton,
  showShareButton,
  showDownloadButton,
  onDownload,
  onEditName,
  onShare,
  onViewChange,
  model,
  view,
}: IModelDetailsRowPropTypes) => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const breakpointChange = breakpoint("md");
  const downloadButtonVisible = showDownloadButton && breakpointChange;
  const modelFinished = model.status === ModelStatus.Finished;

  const getHeading = () => (
    <Heading id="model-name-heading">
      {trimStringIfToLong(model.name, 60)}
    </Heading>
  );

  const getEditBtn = () => (
    <Button
      startIcon={<EditIcon />}
      className="ml-2"
      type="transparent"
      size="small"
      onClick={() => onEditName()}
    >
      {t("editName")}
    </Button>
  );

  const viewButton = () => (
    <div className="md:mr-4">
      <ModelViewTypeButton
        disabledViews={{
          [ModelPageViewTypes.Ortho]:
            !modelFinished ||
            !model.typedModels.find(
              (typed) => typed.type === ITypedModelType.Orthophoto
            ),
          [ModelPageViewTypes.Model]: !modelFinished,
        }}
        onClick={onViewChange}
        currentView={view}
      />
    </div>
  );

  const reportButton = withReportIssueModal(ReportIssueButton)({
    model,
    publicView: isPublic,
  });

  if (breakpointChange) {
    return (
      <DesktopModelDetailsRow>
        <ModelDetailsLeftBox>
          {viewButton()}
          {getHeading()}
          {showEditNameButton && getEditBtn()}
        </ModelDetailsLeftBox>
        <ModelDetailsRightBox>
          {reportButton}
          {downloadButtonVisible && (
            <DownloadAssetsButton onClick={onDownload} />
          )}
          {showShareButton && (
            <SkyeTooltip title={t("share")}>
              <Button
                id="model-share-button"
                className="share-btn right-row-btn"
                endIcon={<ShareIcon />}
                type="light"
                onClick={() => onShare()}
              ></Button>
            </SkyeTooltip>
          )}
          {showCommentsModule && <CommentsCountButtonContainer />}
        </ModelDetailsRightBox>
      </DesktopModelDetailsRow>
    );
  }

  return (
    <DesktopModelDetailsRow>
      <MobileHeadingRow>{getHeading()}</MobileHeadingRow>
      <MobileBtnRow>
        {downloadButtonVisible && <DownloadAssetsButton onClick={onDownload} />}
        {showShareButton && (
          <SkyeTooltip title={t("share")}>
            <Button
              id="model-share-button"
              className="share-btn right-row-btn"
              endIcon={<ShareIcon />}
              type="light"
              onClick={() => onShare()}
            ></Button>
          </SkyeTooltip>
        )}
        {viewButton()}
      </MobileBtnRow>
    </DesktopModelDetailsRow>
  );
};

export default ModelDetailsRow;
