import { UserHelpers } from "../../../../models/User";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GlobalStore from "../../../../stores/GlobalStore";
import styled from "styled-components";
import { trimStringIfToLong } from "../../../../utils/stringUtils";
import { IAppContext } from "../../../interfaces";
import { PROFILE_ROUTES } from "../../../routes";
import Button from "../../../shared/buttons/Button";
import UserCircleIcon from "../../../shared/Icons/UserCircleIcon";
import UserName from "../../../shared/typography/UserName";

interface INavbarMobileUserDetailsPropTypes extends RouteComponentProps {}

const UserIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.average};

  svg {
    width: 40px;
    height: 40px;

    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
    }
  }
`;
const OrgName = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  margin-top: 0;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
`;
const StyledUserName = styled(UserName)`
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  margin-bottom: 8px;
`;

const NavbarMobileUserDetails = ({
  history,
}: INavbarMobileUserDetailsPropTypes) => {
  const { t } = useTranslation();

  const goToOrganizationDetails = () => {
    history.push(PROFILE_ROUTES.account);
  };

  const withClose = (action: any) => () => {
    action();
    GlobalStore.toggleMobileNav(false);
  };

  return (
    <>
      <UserIconWrapper>
        <UserCircleIcon />
      </UserIconWrapper>
      <div>
        <OrgName>
          {trimStringIfToLong(GlobalStore.user?.organization?.name, 30)}
        </OrgName>
        <StyledUserName>
          {trimStringIfToLong(UserHelpers.getUserName(GlobalStore.user), 30)}
        </StyledUserName>
        <Button size="very-small" onClick={withClose(goToOrganizationDetails)}>
          {t("profile")}
        </Button>
      </div>
    </>
  );
};

export default withRouter(NavbarMobileUserDetails);
