import { memo } from "react";
import PaidAccessOverlay from "../../components/PaidAccessOverlay";
import { useTranslation } from "react-i18next";
import { AUTH_ROUTES, PROFILE_ROUTES } from "../../../../routes";
import { useCurrentUser } from "../../../../account/context/user.context";
import { useHistory } from "react-router";

const PublicModelDetailsPagePaidAccessOverlay = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const history = useHistory();

  const handleClick = () => {
    user
      ? window.open(AUTH_ROUTES.register)
      : history.push(PROFILE_ROUTES.subscription);
  };

  return (
    <PaidAccessOverlay
      title={t("unlockModelAccess")}
      description={t("unlockModelDescription")}
      showPayPerModelButton={true}
      payPerModelButtonProps={{
        children: t(user ? "unlockModelTitle" : "signUp"),
        onClick: handleClick,
      }}
    />
  );
};

export default memo(PublicModelDetailsPagePaidAccessOverlay);
