import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { IPublicModel, ModelHelpers } from "models/Model";
import { Polyline } from "@react-google-maps/api";
import {
  GoogleMapService,
  IGoogleMapLocation,
  POLYLINE_OPTIONS,
} from "services/GoogleMapService";
import { ModelService } from "services/ModelService";
import SessionMapLocatorsContainer from "views/session/container/SessionUserMapLocatorsContainer";
import SessionFindingViewerLocatorsContainer from "../container/SessionFindingViewerLocatorsContainer";
import Map from "../../shared/components/Map";
import { ViewerBottomRow } from "../../models/components/viewer-grid";
import { AbsoluteBox, AbsoluteCenterBox } from "../../shared/components/Box";
import SessionViewerToolsPanelContainer from "../container/SessionViewerToolsPanelContainer";
import styled from "styled-components";
import SessionMembersPanelContainer from "../container/SessionMembersPanelContainer";
import { IAppContext } from "../../interfaces";
import sessionViewerUIHook from "../hook/sessionViewerBottomUIHook";
import { STORE_NAMES } from "../../../const/global";
import SessionStreamContainer from "../container/SessionStreamContainer/SessionStreamContainer";
import { ISessionStreamStore } from "../../../stores/SessionStreamStore";

interface IModelMapViewPropTypes {
  SessionStreamStore?: ISessionStreamStore;
  model: IPublicModel;
  zoom?: number;
}

const ToolsWrapper = styled(AbsoluteCenterBox)`
  bottom: 0;
`;
const ViewerSidePanel = styled(AbsoluteBox)`
  top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
const StreamsRow = styled(AbsoluteBox)``;

const SessionModelMapView = ({
  model,
  SessionStreamStore,
}: IModelMapViewPropTypes) => {
  const [controlStyles, streamStyles, rightSidePanelStyles] =
    sessionViewerUIHook({
      streamsOpen: SessionStreamStore.streamPanelOpen,
    });
  const [locations, setLocations] = useState<IGoogleMapLocation[]>([]);

  const getLocation = () => {
    const centerLocation = ModelHelpers.getLocation(model, "centerLocation");
    if (centerLocation.latitude && centerLocation.longitude) {
      return centerLocation;
    }
    return ModelHelpers.getLocation(model);
  };

  const loadPaths = async () => {
    if (model.assets.gpsUrl) {
      ModelService.getModelGpsPath(model).then((locations) =>
        setLocations(locations.map(GoogleMapService.getLocationPoint))
      );
    }
  };

  const handleLoad = () => {
    if (model.location && model.location.point) {
      loadPaths();
    }
  };

  const location = getLocation();
  const modelLocationPoint = {
    lat: location.latitude,
    lng: location.longitude,
  };

  return (
    <Map onLoad={handleLoad} centerPoint={modelLocationPoint}>
      <ViewerSidePanel
        style={rightSidePanelStyles}
        className="right-side-panel"
      >
        <SessionMembersPanelContainer />
      </ViewerSidePanel>
      <ViewerBottomRow style={controlStyles}>
        <ToolsWrapper>
          <SessionViewerToolsPanelContainer />
        </ToolsWrapper>
      </ViewerBottomRow>
      <StreamsRow style={streamStyles}>
        <SessionStreamContainer />
      </StreamsRow>
      <SessionMapLocatorsContainer />
      <SessionFindingViewerLocatorsContainer type="map" />
      {!!locations.length && (
        <Polyline path={locations} options={POLYLINE_OPTIONS} />
      )}
    </Map>
  );
};

export default inject(STORE_NAMES.SessionStreamStore)(
  observer(SessionModelMapView)
);
