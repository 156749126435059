import { inject, observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import useBreakpoint from "../../hook/useBreakpointHook";
import MobileNavbar from "../../../../views/shared/components/Navbar/MobileNavbar";
import NavbarSignInInfo from "../../../../views/shared/components/Navbar/NavbarSignInInfo";
import Heading from "../../../../views/shared/typography/Heading";
import AuthorizedDesktopNavbarContent from "./AuthorizedDesktopNavbarContent";
import NavbarMobileUserDetails from "./NavbarMobileUserDetails";
import NavbarBase from "./NavbarBase";
import styled from "styled-components";
import NavbarUserDetailsDropdown from "./NavbarUserDetailsDropdown";
import breakpoint from "styled-components-breakpoint";

interface IPublicNavbarPropTypes extends RouteComponentProps {
  GlobalStore?: IGlobalStore;
}

const DesktopNavbar = styled.div`
  display: none;

  ${breakpoint("md")`
    display: block;
  `}
`;

const PublicNavbar = ({ GlobalStore }: IPublicNavbarPropTypes) => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const loaded = GlobalStore.userLoaded;
  const isLogged = GlobalStore.isLoggedIn();

  const desktop = breakpoint("lg");

  const renderDesktopRightSide = () => {
    if (!loaded) return null;
    return isLogged ? <NavbarUserDetailsDropdown /> : <NavbarSignInInfo />;
  };

  return (
    <>
      {desktop && (
        <DesktopNavbar>
          {/* {isLogged && <SubscriptionInformationBar />} */}
          <NavbarBase rightSide={renderDesktopRightSide()}>
            {isLogged ? (
              <AuthorizedDesktopNavbarContent />
            ) : (
              <Heading>{t("model")}</Heading>
            )}
          </NavbarBase>
        </DesktopNavbar>
      )}
      {!desktop && (
        <MobileNavbar
          showButtons={isLogged}
          topSection={
            isLogged ? <NavbarMobileUserDetails /> : <NavbarSignInInfo />
          }
        ></MobileNavbar>
      )}
    </>
  );
};

export default inject("GlobalStore")(withRouter(observer(PublicNavbar)));
