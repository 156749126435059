import React, {ReactNode, useState} from "react";
import styled from "styled-components";
import { IAppContext, ModelType } from "../../../../views/interfaces";
import Box from "../Box";
import breakpoint from "styled-components-breakpoint";
import { Link } from "react-router-dom";
import { getModelListPageUrl } from "../../../../utils/globalUtils";
import Badge from "../badges/Badge/Badge";
import { getEnv, isProduction } from "../../../../env";

export interface INavbarBasePropTypes {
  children: ReactNode;
  rightSide?: ReactNode;
}

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  border-bottom: ${(p: IAppContext) => p.theme.skye.borders.primary};
  transform: translateY(0vh);
  width: 100%;
  height: 60px;
  background: #fff;
  height: ${(p) => p.theme.skye.elementSizes.navbarHeight}
  transition: all 0.2s ease-out;
  z-index: 1000;

  ${breakpoint("md")`
    top: 0;
    position: relative;
    padding-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  `}
`;

const HeaderContent = styled.div`
  display: flex;

  h1 {
    margin-bottom: 0;
  }
`;

const HeaderLogoWrapper = styled(Link)`
  position: relative;
  min-width: 160px;
`;
const HeaderLogo = styled.img`
  height: 32px;
  width: auto;
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  margin-right: 48px;

  ${breakpoint("md")`
    margin-left: 0;
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  `}
`;

const LeftSide = styled(Box)`
  align-items: center;
`;
const RightSide = styled(Box)`
  align-items: center;
  justify-content: flex-end;
`;
const EnvBadge = styled.div`
  position: absolute;
  top: 28px;
  right: 5px;
`;

const NavbarBase = ({ children, rightSide }: INavbarBasePropTypes) => {
  return (
    <HeaderContainer className="navbar-base logged-in">
      <LeftSide>
        <HeaderLogoWrapper to={getModelListPageUrl(ModelType.Owned)}>
          <HeaderLogo
            src="/img/logo_black_horizontal.svg"
            alt="skyebrowse_logotype"
          />
          {/* Information badge for testers */}
          {!isProduction() && (
            <EnvBadge>
              <Badge color="danger">{getEnv()}</Badge>
            </EnvBadge>
          )}
        </HeaderLogoWrapper>
        <HeaderContent className="header-content">{children}</HeaderContent>
      </LeftSide>
      <RightSide>{rightSide}</RightSide>
    </HeaderContainer>
  );
};

export default NavbarBase;
